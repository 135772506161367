@import "../mixins.scss";

*{
  list-style-type: none;
}

.docker-main-wrap {
  padding: 10px 200px;
  @include respond(phone) {
    padding: 0%;
  }
  @include respond(tab) {
    padding: 10px 50px;
  }
}
.tutorial-links{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  h2{
    color: grey;
    margin-top: 20px;
    @include respond(phone) {
      font-size: 23px;
      text-align: center;
    }
  }
  a{
    color: rgb(94, 94, 195);;
    
  }

}
.video-dropdown{
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  cursor: pointer;
  color: #007bff;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 7px;
  font-weight: bold;
  font-size: 14px;
  transition: background-color 0.3s ease;
  @include respond(phone) {
    font-size: 10px;
    gap: 3px;
  }

  &:hover {
    background-color: rgba(0, 123, 255, 0.8);
  }
}
.tuto-link{
  text-align: right;
}
.tutorials{
  display: flex;
  flex-direction: column;
  align-items: center;
  .tut-iframe {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 50px;
    @include respond(phone) {
      display: flex;
      flex-direction: column;
    }
    .video-wrap {
      width: calc((100% - 70px) / 3); 
      height: 150px;
      margin-bottom: 30px;
      box-sizing: border-box;
      display: flex;
        flex-direction: column;
        align-items: center;
      @include respond(tab) {
        width: calc((100% - 70px) / 2); 
      }
      @include respond(phone) {
        width: 100%; 
      }
      
    }
  }
}

.video-title{
  width: fit-content;
  font-size: 16px;
  margin-top: 5px;
  line-height: 1.3;
}
// .video-iframe{
//   width: 560px;
//   height: 315px;
// }

.contain-wrap{
  margin-top: 30px;
}
.hero-text-wrap {
  display: flex;
  justify-content: center;

  flex-direction: column;
  text-align: center;
  h1 {
    margin-bottom: 10px;
    @include respond(phone) {
      padding-top: 60px;
      font-size: 28px;
    }
  }
  p {
    color: grey;
    margin-bottom: 30px;
  }
}
.header {
  text-align: center;
  font-size: 22px;
  color: #fff;
  margin-bottom: 20px;
}
.success-message {
  text-align: center;
  color: green;
}
.docker-wrap {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
  @include respond(phone) {
    display: flex;
    flex-direction: column;
  }
}
.forms-wrapper {
  width: 50%;
  @include respond(phone) {
    width: 100%;
  }
 
}

.input-with-tooltip {
  margin-bottom: 10px;
  input {
    padding: 10px 20px;
    border-radius: 7px;
    width: 100%;
    outline: none;
  }
}
.top-text-wrap {
  display: flex;
  gap: 4px;
  margin-bottom: 10px;
  font-size: 16px;
  color: grey;
  .tooltiptext {
    font-size: 4px;
    color: grey;
  }
}
.warning-wrapper{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.add-btn {
  padding: 10px 20px;
  cursor: pointer;
  // color: #007bff;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(0, 123, 255, 0.8);
  }
}
.disable-btn{
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: gray;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  transition: background-color 0.3s ease;

}

.wrap-action-btn{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.doc-box {
  width: 50%;
  @include respond(phone) {
    width: 100%;
  }
}

.config-action-buttons-demo{
  width: 100%;
  height: 100%;
  @include respond(phone) {
    width: 100%;
  }
}
.demo-docker{
  margin-top: 12px;
  color: rgb(248, 248, 242);
  background: rgb(43, 43, 43);
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  
  h4{ 
    width: 200px;
    text-align: center;
    
  }

  @include respond(phone) {
    width: 100%;
    padding: 50px 0px;
  }
}
.download-wrap-demo{
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.download-button-demo {
  padding: 10px 20px;
  cursor: pointer;
  color: #007bff;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  transition: background-color 0.3s ease;

 
}

.download-wrap {
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.step-info {
  display: flex;
  justify-content: space-between;
}
.copy-button,
.download-button {
  padding: 10px 20px;
  cursor: pointer;
  color: #007bff;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(0, 123, 255, 0.8);
  }
}
.docker-compose-config {
  margin-top: 20px;
  border: 5px solid #ccc;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;

  // pre {
  //   font-size: 14px;
  //   white-space: pre-wrap;
  //   word-wrap: break-word;
  //   max-height: 300px;
  //   overflow-y: auto;
  //   border: none;
  //   border-radius: 5px;
  // }
}

.docker-compose-config-demo{
  margin-top: 20px;
  border: 5px solid #ccc;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
  height: 70%;
  @include respond(phone) {
    width: 100%;
    height: 70%;
  }
}

.instruction-Wrap {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 15px;
  padding: 20px 10px;
  border: 1px solid grey;
  border-radius: 10px;
  @include respond(phone) {
    display: flex;
    flex-direction: column;
  }
}

.instru-left-wrap{
  // width: fit-content;
  padding-left: 10px;
}
.instru-right-wrap{
  // width: fit-content;
  padding-left: 10px;
  
}
.instruct {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  h2 {
    margin-bottom: 3px;
    text-align: center;
  }
  p {
    margin-bottom: 3px;
    line-height: 1.1;
    font-size: 16px;
  }
  ul {
    padding:0 20px;
    color: grey;
    list-style-type: square;
    li {
      margin-bottom: 3px;
      list-style-type: square;
      line-height: 1.6;
    }
  }
  h4 {
    color: #fff;
    margin-bottom: 4px;
  }
}


.doc-compose {
  width: 100%;
  cursor: pointer;
  @include respond(phone) {
    width: 100%;
  }
}

.dock-comp-cli{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}

.docker-i-f-i{
  text-align: center;
}