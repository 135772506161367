@import "../../mixins.scss";
@import "../../variables.scss";

.navbar {
  align-items: center;
  background: linear-gradient(52.21deg, #19163b 46.97%, #4c09a2 156.47%);
  background: var(--background-color);
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: 1240px;
  padding: 10px 16px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  

  .logos, .logo {
    width: 70px;
  }
  .logo-android-nav{
    padding-top: 15px;
    padding-left: 15px;
  }
  .btn-search-wrap {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .search-wrap {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    border: 1px solid grey;
    border-radius: 10px;
    @include respond(phone) {
      display: none;
    }

    //
    .input {
      background: #0b1126;
      border: none;
      outline: none;
      width: 180px;
      color: #fff;
    }
  }
  .search-wrap-sm-nav {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    border: 1px solid grey;
    border-radius: 10px;

    //
    .input-sm-nav {
      background: #0b1126;
      border: none;
      outline: none;
      width: 180px;
      color: #fff;
    }
  }
  .lists-1 {
    color: #fff;
  }
  .btn-login {
    padding: 12px 50px;
    border: none;
    font-size: 16px;
    color: white;
    border-radius: 15px;
    border-radius: 50px;
    background-color: #007bff;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;

    // background-image: linear-gradient(to right, #46aef2, #4968ea);
    &:hover {
      background-image: linear-gradient(to left, #4968ea, #46aef2);
      // background-image: -webkit-gradient(linear, left top, right top, from(#4968ea), color-stop(#46aef2), color-stop(#46aef2), to(#4968ea));
    }
    @include respond(phone) {
      display: none;
    }
  }

  .links {
    display: flex;
    list-style: none;
    gap: 30px;
    @include respond(phone) {
      display: none;
    }
  }
  .toggle {
    display: block;
    overflow: hidden;

    @include respond(tab) {
      display: none;
    }
    @include respond(desktop) {
      display: none;
    }
  }

  .side-nav {
    z-index: 10;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 80%;
    height: 100%;
    background-color: #0000007f;
    backdrop-filter: blur(10px);
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
    color: white;
    @include respond(desktop) {
      display: none;
    }
    @include respond(tab) {
      width: 30%;
    }

    h1 {
      width: 100%;
      font-size: 1.875rem /* 30px */;
      line-height: 2.25rem;
      font-weight: 700;
      margin: 1rem /* 16px */;
    }
    ul {
      padding: 1rem /* 16px */;
      // text-transform: uppercase;
      color: white;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      // background-color: yellow;
    }
    li {
      padding: 16px 0px /* 16px */;
      border-bottom-width: 1px;
      list-style: none;
      font-size: 20px;
      cursor: pointer;
    }
  }
  .breakaway-wrap {
    padding: 0px 20px;
  }
  .break {
    padding-left: 0%;
    font-size: 16px !important;
    font-weight: 500;
    color: rgba(236, 226, 226, 0.748);
  }
  .break-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    color: rgba(236, 226, 226, 0.748);
  }
  .side-nav-else {
    position: fixed;
    left: -100%;
  }
  .lists {
    color: rgba(236, 226, 226, 0.748);
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 10px;
    &:hover {
      color: aqua;
      font-size: 12;
    }
  }
  .host-btn {
    padding: 10px 30px;
    border-radius: 12px;
    background: var(--mixed-color);
    transition: background-position 0.5s ease-in-out 0s;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    @include respond(phone) {
      display: none;
    }
  }
  .btn-side {
    margin-top: 100px;
    padding: 7px 0;
    border-radius: 7px;
    width: 90%;
    margin-bottom: 10px;
    @include respond(sm-phone) {
      margin-top: 30px;
    }
  }
  .social-icons {
    color: rgba(236, 226, 226, 0.748);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}
.admin-logout{
  cursor: pointer;
  // position: absolute;
  // top: 20px;
  // right: 20px;
  padding: 10px 30px;
      border-radius: 12px;
      background-color: #007bff;
      color: #fff;
}