@import "../../mixins.scss";
@import "../../variables.scss";
.platformsm-container{
    margin: 30px 0px;
    @include respond(desktop) {
        display: none;
      }
}
.platformsm-wrap{
    h1{
        padding: 0px 30px;
        font-size: 22px;
        text-align: center;
    }
}
.platformsm-box {
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    cursor: pointer;
    
    a {
      padding: 7px 20px;
      border-radius: 6px;
      background: var(--mixed-color);
      color: #fff;
      margin-bottom: 10px;
      text-align: center; 
      width: fit-content; 
    }
  
    img {
      max-width: 100%;
      height: auto;
    }
  }