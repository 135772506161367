:root {
    --mixed-color: linear-gradient(70deg, rgb(41, 188, 250) 14.23%, rgb(100, 100, 228) 45.15%, rgb(180, 90, 250) 86.14%) 50% 50% / 200% 100%;;
    --dark-blue-color: rgb(14, 10, 49);
    --background-color: linear-gradient(52.21deg, #19163B 46.97%, #4C09A2 156.47%);
    --text-grey: grey;
    --light-purple:rgb(194, 147, 255);
    --text-white: #fff;
    --transparent-color: transparent;
    --box-shadow: rgba(159, 98, 242, 0.2) 0px 0px 24px 0px;
    --bg-input: linear-gradient(91deg, rgba(159, 98, 242, 0.16) -4%, rgba(95, 237, 235, 0) 120.34%);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0px 19px 45px 0px #29236f3d;
  }