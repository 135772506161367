@import "../mixins.scss";
.landing-page-wrap{
  // max-width: 1200px;
  // margin-left: auto;
  // margin-right: auto;
  overflow-y: scroll !important;
  height: 100%;
  // @include respond(phone) {
  //   width: 350px;
  //   margin: 0px auto;
  //   padding: 50px 10px !important;
  // }
  @include respond(tab) {
    margin-left:20px;
    margin-right: 30px;
    padding: 50px 10px !important;
  }
}
