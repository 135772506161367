@import "../mixins.scss";


.admin-container-wrap{
    h1{
        text-align: center;
        padding-top: 70px;
        padding-bottom: 10px;
        @include respond(phone) {
          font-size: 18px;
    
        }
    }
    p{
        text-align: center;
        font-size: 20px;
        @include respond(phone) {
          font-size: 16px;
    
        }
    }
}

.tab-buttons{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 6px;
  button{
    padding: 7px 20px;
    border-radius: 8px;
    background: var(--mixed-color);
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    @include respond(phone) {
      font-size: 16px;
    }
  }
}
table {
    width: 100%;
    border-collapse: collapse;
    padding: 0px 30px;
    @include respond(phone) {
      padding: 0px 0px;

    }
    .aboutP{
      display: none;
    }
  }
  thead{
    // background-color: #F9FAFB;
    background-color: #007bff;
    border-radius: 4px 4px 0px 0px;
  } 
  
  .table-wrap{
    margin: 10px 40px;
    @include respond(phone) {
      margin: 10px 0px; 
    }
    .domain,.platfrom-creator,.community-id,.constainername{
      @include respond(phone) {
        display: none;
        }
    }


   
  }
  .table-wrap th {
    position: sticky;
    top: 0; 
  }
  .table-wrap::-webkit-scrollbar {
    display: none;
  }
th,td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid;
    border-color: rgba(229, 229, 229, 0.1);
    padding-left: 26px;
    padding-right: 26px;
    @include respond(phone) {
      padding-left: 0px;
      padding-right: 0px;

    }
    @include respond(iphone-12-pro) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
th {
    // background-color: #007bff;
    border-top: 1px solid;
    border-color: rgba(229, 229, 229, 0.1);
    font-weight: bold;
    
    @include respond(phone) {
      font-size: 12px;
    }
  }
tbody{
  
    @include respond(phone) {
      font-size: 12px;
    }
  }
td{
    border-right: 1px solid #F9FAFB;
    border-bottom: 1px solid #F9FAFB;
    border-left: 1px solid #F9FAFB;
    font-size: 16px;
    @include respond(phone) {
      font-size: 12px;
    }
  }
  .success-text{
    background-color: var(--tg-light-green);
    color: #1ACE37;
    padding: 3px 5px;
    border-radius: 12px;
    font-size: 14px;

  }
  .fail-text{
    background-color:#e944443d ;
    color: #d13131;
    padding: 3px 14px;
    border-radius: 12px;
    font-size: 14px;
  }
  .pending-text{
    background-color:rgba(255, 255, 0, 0.055) ;
    color: yellow;
    padding: 3px 14px;
    border-radius: 12px;
    font-size: 14px;
  }
  .action-icon{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    width: 100%;
    img{
        width: 30%;
    }
  }

  .review-btn{
    padding: 6px 15px ;
    border-radius: 7px ;
    background: var(--mixed-color);
    color: #fff;
    cursor: pointer;
    @include respond(phone) {
      padding: 3px 8px ;
      border-radius: 4px ;
      font-size: 12px;
    }
  }

  .empty {
    text-align: center;
    font-size: 20px;
    padding: 20px;
    color: #999;
    width: 100%;
  }
  
  .table-body td {
    text-align: center;
  }
  .all-list{
    margin-top: 100px;
    h3{
      text-align: center;
      margin-bottom: 8px;
      font-size: 22px;
    }
  }

  .approved {
    background-color: green;
  }
  
  .cancel {
    background-color: rgb(255, 39, 39);
  }
  
  .pending {
    background-color: rgb(219, 203, 107);
    color: black;
  }
  .status-color{
    padding: 3px 8px ;
    border-radius: 8px;
    font-weight: 700;
    box-sizing: border-box;
  }