@import "../../mixins.scss";
.hero-section{
  position: relative;
  min-height: 90vh;
  @include respond(phone) {
    min-height: 60vh;
  }
  @include respond(tab) {
    min-height: 60vh;
  }
}
.wave{
  position: absolute;
  background-size: cover;
  width: 100%;
  opacity: .45;
  top: -20%;
  z-index: 1;
  @include respond(phone) {
    top: 40%;
  }


}
.hero-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 3;
    h1{
      font-size: 60px;
      margin-bottom: 30px;
      padding-top: 120px;
      @include respond(phone) {
        font-size: 28px;
        text-align: start;
        padding-top: 60px;
      }
      
    }
    p{
      font-size: 22px;
      margin-bottom: 20px;
      width: 70%;
      text-align: center;
      margin: 0px 200px;
      margin-bottom: 12px;
      line-height: 30px;
      @include respond(phone) {
        font-size: 20px;
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
      }
      @include respond(tab) {
        line-height: 1.3;
        font-size: 28px;
      }
  
    }
    .btn-hero{
      padding: 13px 70px;
      border-radius: 12px;
      font-size: 18px;
      font-weight: 900;
      margin-bottom: 40px;
      cursor: pointer;
      @include respond(phone) {
        padding: 12px 50px;
        line-height: 1.8rem;
        display: none;
       
        
      }
  
    }
  }
  .phone-text-btn{
    padding: 10px 30px;
      border-radius: 0.5rem;
      font-size: 18px;
      font-weight: 900;
      margin-bottom: 40px;
      display: inline;
      @include respond(desktop) {
        display: none;
        
      }
      @include respond(tab) {
        display: none;
        
      }

  }
  .carousel{
    display: flex;
    a{
      color: #fff;
      cursor: pointer;
    }
  }
  .carousel-wrap{
    display: flex;
    align-items: center;
    padding: 20px 50px;
    margin-left: 0.75rem/* 12px */;
    border-radius: 0.375rem/* 6px */;
    gap: 15px;
    // border: 1px solid grey;
    border-radius: 10px;
  
   
  }
  .caro-img{
    width: 70px;
    border-radius: 20px;
  }

 