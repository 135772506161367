@import "../mixins.scss";
.login-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    

    .login-content-wrap{
        h1{
            text-align: center;
            margin-bottom: 15px;
            @include respond(phone) {
               font-size: 18px;
              }
        }
        padding: 20px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        width: 40%;
        background-color: rgb(14, 10, 49);
        box-shadow: rgba(159, 98, 242, 0.2) 0px 0px 24px 0px;
        @include respond(phone) {
            width: 95%;
          }
 form{
    display: flex;
    justify-content: center;
    flex-direction: column;
    
 }
        .btn-login{
            padding: 10px 50px;
            border-radius: 10px;
            background: linear-gradient(70deg, rgb(41, 188, 250) 14.23%, rgb(100, 100, 228) 45.15%, rgb(180, 90, 250) 86.14%) 50% 50% / 200% 100%;
            color: #fff;
            font-size: 18px;
            margin: auto;
            cursor: pointer;
            @include respond(phone) {
                padding: 8px 50px;
              }
        }

    }


    .input-wrap{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;
        input{
            outline: none;
            border-bottom: 1px solid gray;
            border-bottom: 1px solid gray;
            padding: 10px;
            background-color: inherit;
            color: #fff;
        }

    }

}