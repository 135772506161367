@import "../../mixins.scss";
@import "../../variables.scss";

.box-container-grid{
    width: 100%;
}
.box-grid{
    padding: 0px 10px;
    padding-top: 3px;
    width: 265px;
    height: 410px;
    gap: 10px;
    // background-color: #0b1126;
    background: rgb(14, 10, 49);
    margin-bottom: 10px;
    border-radius: 10px;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    @include respond(tab) {
        width: 100%;
        height: 505px;
        margin-bottom: 0px;
      }


    @include respond(phone) {
        width: 100%;
        height: 100%;
      }
}
.box-grid-bac{
    padding: 0px 10px;
    padding-top: 3px;
    width: 265px;
    height: 410px;
    gap: 10px;
    // background-color: #0b1126;
    background: rgb(74, 39, 128);
    margin-bottom: 10px;
    border-radius: 10px;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    @include respond(tab) {
        width: 100%;
        height: 400px;
        margin-bottom: 0px;
      }


    @include respond(phone) {
        width: 100%;
        height: 100%;
      }
}
.box-wrap-left-grid{
    display: flex;
    flex-direction: column;
    
}
.img-cover-grid{
    width: 100%;
    // height: 210px;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    @include respond(tab) {
        // margin-top: 10px;
      }
    
    
    // background-size: ;
    
}
.pro-img-grid{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}
.bac{
    // font-size: 18px;
    border-radius: 20px;
    padding: 5px 15px;
    width: 90px;
    // background: var(--mixed-color);
    position: absolute;
    right: 5px;
}


.title-grid{
    color: gray;
    font-size: 18px;
    margin-bottom: 7px ;
    font-weight: 800;
    list-style: none;
    @include respond(phone) {
        margin-top: 10px;
      }
}
.title-grid-bac{
    color: #fff;
    font-size: 18px;
    margin-bottom: 7px ;
    font-weight: 800;
    list-style: none;
    @include respond(phone) {
        margin-top: 10px;
      }
}
.about-grid{
    width: fit-content;
    font-size: 15px;
    margin-bottom: 3px;
    color: #fff;
    font-size: 14.4px;
    // line-height: 1.1;
    @include respond(phone) {
        font-size: 16px;
        line-height: 1.1;
      }

}
.last-num{
    @include respond(phone) {
        display: none; 
    }

}
.box-left-grid{
    display: flex;
    flex-direction: column;
    padding-top: 7px;
    align-items: center;
    @include respond(phone) {
        padding: 0px 10px; 
    }
    @include respond(tab) {
        align-items: center;
    }
}
.community-info-grid{
    display: flex;
    margin: 7px 0px;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    @include respond(tab) {
        gap: 5px;
        padding: 7px;
       flex-direction: column;
       align-items: center;
      }
      @include respond(phone) {
        gap: 5px;
        padding: 7px;
        flex-direction: row;
        align-items: center;
      }
    
}

.title-and-info-wrapper{
    display: flex;
    align-items: center;
    gap: 3px;
    cursor: pointer;
}