*{
  margin: 0px;
  padding: 0px;
  font-family: system-ui, sans-serif;
  box-sizing: border-box !important;
  border: none;
}

*::-webkit-scrollbar {
  display: none;
}
@import "./mixins.scss";
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.container {
 height: 100vh;
 overflow-x: hidden !important;
 scroll-behavior: smooth;
  @include respond(phone) {
    padding: 0px 15px; 
  }
}

.app-container {
  flex: 1;
  width: 100%;
  margin: 0px 10px;
  @include respond(phone) {
    margin: 0px;
  }
}

.warning{
  color: rgb(236, 121, 121);
  margin-bottom: 20px;
}


.double-color{
  background: linear-gradient(180deg, #fff 0, #93b2c8 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
