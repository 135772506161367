@import "../mixins.scss";
@import "../variables.scss";



.communities-wrapper{
    margin-left: auto;
    margin-right: auto;
    max-width: 1100px;
    /* overflow-x: visible !important; */
    /* overflow-y: scroll !important; */
    position: fixed;
    left: 0;
    right: 0;


    @include respond(phone) {
        margin: 0px 10px;
      }


}




.hero-text{
    h1{
        font-size: 45px;
        font-family: Roboto;
        font-weight: 700;
        text-align: center;
        margin-bottom: 40px;
        
        @include respond(phone) {
            font-size: 25px;
            margin-bottom: 25px;
            
          }

    }
}

.search-wrap{
    display: flex;
    padding: 8px 12px;
    align-items: center;
    border: 1px solid grey;
    border-radius: 10px;
    width: fit-content;
    box-shadow: rgba(159, 98, 242, 0.2) 0px 0px 24px 0px;
    cursor: pointer;
    @include respond(phone) {
        width: 160px;
        border: 1px solid grey;
        padding: 8px 7px;
    }

    .input{
      background: inherit;
      border: none;
      outline: none;
      width: 180px;
      color: #fff !important;
      font-size: 16px;
      
      @include respond(phone) {
        width: 120px;
    }
      
    }
  }

.select{
    padding: 5px 10px;
    border-radius: 7px;
    background: grey;
    outline: none;
    @include respond(phone) {
        width: 180px;
        padding: 5px 7px;
    }
}
.search-icon{
    cursor: pointer;
    @include respond(phone) {
        display: none;
    }
}
.grid-btn{
    padding: 2px 10px;
    border-radius: 7px;
    background: grey;
    cursor: pointer;
    font-size: 16px;
    @include respond(phone) {
        display: none;
    }

}
.community-section{
    width: 100%;
}
.community-box {
    height: 90vh;
    overflow-y: scroll !important;
    scroll-behavior: smooth;
}
.community-box-grid{
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto;
    height: 90vh;
    overflow-y: scroll !important;
    scroll-behavior: smooth;
    @include respond(tab) {
        grid-template-columns:  auto auto auto;
        
    }
    @include respond(phone) {
        height: 80vh;
        display: flex;
        flex-direction: column;
    }
    
}
// .box-container{
//     display: flex;
// }
.box{
    background: #0b1126;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
    border: 3px black;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 7px;
    margin-bottom: 10px;
    padding: 10px 4px;
    @include respond(phone) {
        border-radius: 10px;
    }
}
.box-bac{
    // background: #0b1126;
    background: rgb(74, 39, 128);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
    border: 3px black;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 7px;
    margin-bottom: 10px;
    padding: 10px 4px;
    @include respond(phone) {
        border-radius: 10px;
    }
}
.box-wrap-left{
    display: flex;
    align-items: center;
    gap: 20px;
    @include respond(phone) {
        display: flex;
        flex-direction: column;
        gap: 10px;
        
      }
}
.img-cover{
    position: relative;
    @include respond(phone) {
    width: 100%;
    height: 210px;
    }
    
}
.bac-list{
    position: absolute;
    top: 10;
    width: 50px;
}
.pro-img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    
    @include respond(phone) {
        width: 100%;
        // height: 100%;
        border-radius: 6px;
        object-fit: cover;
        
        
        
    }
}
.box-left{
    display: flex;
    flex-direction: column;
    padding-top: 7px;
    @include respond(phone) {
        padding: 0px 10px; 
    }
}
.title{
    color: #fff;
    font-size: 22px;
    margin-bottom: 7px;
    font-weight: 800;
    list-style: none;
    @include respond(phone) {
        font-size: 18px !important;
        font-weight: 600;
        
    }

}
.about{
    width: fit-content;
    font-size: 15px;
    margin-bottom: 3px;
    color: grey;
    font-size: 14.4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; 
    @include respond(phone) {
        display: none;
      }
    
}
.about-phone{
    color: grey;
    font-size: 14px;
    line-height: 1.5em;
    @include respond(desktop) {
        display: none;
        
      }
      @include respond(tab) {
        display: none;
        
      }
}
.community-info{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.center-items{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
}
.info-icons{
    margin-right: 20px;
    color: grey;
    @include respond(phone) {
        margin-right: 13px;
      }
}
.info-left{
    display: flex;
    // gap: 8px;
    @include respond(phone) {
        // gap: 4px;
      }
}
.btn-vist-phone{
    @include respond(tab) {
        display: none;
      }
      @include respond(desktop) {
        display: none;
      }
}
.select-communities{
    margin-bottom: 7px;
    display: flex;
    justify-content: space-between;
    
}
.select{
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
}

.community-info{
    display: flex;
    margin-bottom: 7px;
    
}
.info-num{
    font-size: 14px;
    font-weight: 800;
    color: rgb(194, 201, 212);
    @include respond(phone) {
        font-weight: 700;
      }
}
.btn-wrap{
    margin-right: 10px;
    @include respond(phone) {
        display: none;
      }
    
}
.btn{
    padding: 4px 20px;
    border-radius: 12px;
    border: none;
    font-weight: 700;
    width: 100%;
    cursor: pointer;
    
}
.admins-wrapper{
    // margin-top: 10px;
    font-size: 13px ;
    color: #fff;
    display: flex;
    gap: 2px;
    @include respond(phone) {
        display: none;  
      }
    .admins{
        display: flex;
        gap: 5px;
    }
    
}
.start-com-wrap{
    font-size: 13.33px;
    padding: 4px 20px;
    border-radius: 12px;
    color: black;
    cursor: pointer;
    font-weight: bold;
    @include respond(phone) {
        padding: 5px 9px;
        font-size: 14px;
        
      }
    
}
.start{
    font-size: 18px;
    color: grey !important;
}
