@import "../mixins.scss";

.create-community {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  
    .create-community-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      padding: 30px;
      border-radius: 10px;
      width: 400px;
      background-color: var(--dark-blue-color);
      box-shadow: var(--box-shadow);

      
      
  
      .header {
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: bold;
        @include respond(phone) {
          margin-bottom: 5px;
        }
   
      }
  
      .error-message {
        color: red;
        margin-bottom: 20px;
        font-size: 16px;
      }
  
      .success-message {
        color: green;
        margin-bottom: 20px;
        font-size: 16px;
      }

      .progress-bar {
        display: flex;
        justify-content: space-between;
        gap: 5px;
        width: 100%;
        margin-bottom: 10px;
      
        .progress-line {
          flex: 1;
          height: 5px;
          background-color: rgb(239, 124, 37);
          &.completed {
            background-color: green;
          }
        }
      }

      .step-info{
        margin: 15px 0;
        .info{
            font-size: 14px;
            font: bold;
            color: var(--light-purple);
        }
      }

      
  
      .form-wrapper {
        display: flex;
        flex-direction: column;
        gap: 30px;
  
        input, textarea {
            padding: 10px;
            border: none;
            border-bottom: 1px solid gray;
            outline: none;
            font-size: 14px;
            color: var(--text-white);
            background-color: var(--bg-input);
          }

          .warnings{
              font-size: 14px;
              font: bold;
              color: var(--light-purple);
        }

          .password-input, .community-input{
            display: flex;
            // position: relative;
            align-items: center;
            width: 100%;
            input{
                font: bold;
                font-size: 16px;
                width: 100%;
                flex: 3;
            }

            .svg{
                padding: 3px;
                cursor: pointer;

                &:hover {
                  color: #007bff;
                }
            }

          }
          
          .community-input{
            margin-top: 15px;
          }
  
        button {
          padding: 10px;
          cursor: pointer;
          background-color: #007bff;
          color: white;
          border: none;
          border-radius: 5px;
          font-weight: bold;
          font-size: 16px;
          transition: background-color 0.3s ease;
  
          &:hover {
            background-color: #0056b3;
          }
        }

        .btn-create{
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 30px;
        }
        
        .keychain-img{
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }

      .community-success{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;

        .succes-top{
          display: flex;
          flex-direction: column;
          gap: 15px;
        }

        button {
          padding: 10px;
          cursor: pointer;
          background-color: #007bff;
          color: white;
          border: none;
          border-radius: 5px;
          font-weight: bold;
          font-size: 16px;
          transition: background-color 0.3s ease;
  
          &:hover {
            background-color: #0056b3;
          }
        }
      }
    }
  }
  
  .download-keys{
    display: flex;
    align-items: center;
    gap: 5px;
    align-self: center;
    border-radius: 50%;
    margin-top: 15px;
    .svg{
      width: 30px;
    }
  }
.community-input-add{
  display: flex;
  flex-direction: column;
  background: var(--bg-input);
  padding: 10px 0px;
  border-radius: 16px ;
  border: 1px solid rgba(71, 66, 118, 0.4);
  .operation-info{
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    padding: 5px 5px;
    font-size: 14px;
    color: var(--light-purple);
  }
}
.community-input-addn{
  // display: flex;
  // flex-direction: column;
  // background: var(--bg-input);
  padding: 10px 5px;
  border-radius: 16px 16px 0px 0px ;
  border:  1px   solid rgba(71, 66, 118, 0.4);
  border-bottom: 0px;
}
.password-sec-wrap{
  display: flex;
  flex-direction: column;
  background: var(--bg-input);
  padding: 10px 5px;
  border-radius: 16px ;
  border: 1px solid rgba(71, 66, 118, 0.4);

}
